<template>
  <div style="margin: 24px">
    <div
        style="margin-bottom: 15px;z-index: 8848!important;display: flex;flex-direction: row-reverse;justify-content: space-between">
      <a-pagination v-if="modeChange == 'record'"
                    :default-current="1"
                    v-model="curr"
                    :pageSize="12"
                    :total="this.returnBack.count"
      />

      <a-pagination v-if="modeChange == 'update'"
                    :default-current="1"
                    v-model="upCurr"
                    :pageSize="12"
                    :total="this.updateBack.count"
      />


      <a-pagination v-if="modeChange == 'helpful'"
                    :default-current="1"
                    v-model="helpCurr"
                    :pageSize="12"
                    :total="this.updateBack.count"
      />


      <div v-if="isComp" style="display: flex;">
        <a-radio-group v-model="modeChange">
          <a-radio-button value="record">
            作图记录
          </a-radio-button>
          <a-radio-button value="update">
            更新记录
          </a-radio-button>
          <a-radio-button value="helpful">
            帮助中心
          </a-radio-button>

        </a-radio-group>

        <div style="margin-left: 5px" v-if="modeChange == 'record'">
          <a-input-search placeholder="Steam17 位ID" style="width: 150px" @search="onSSS"/>
        </div>
      </div>

    </div>
    <a-table
        style="border-radius: 15px;overflow: hidden;border: 1px solid rgba(0,0,0,0.2)"
        v-if="modeChange == 'record'"
        ref="table"
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.pid"
        :pagination="false"
    >
<!--      &lt;!&ndash;        eslint-disable-next-line vue/no-unused-vars &ndash;&gt;-->
<!--      <template slot="operation" slot-scope="text, record">-->

<!--        <a @click="download(record)">下载</a>-->

<!--      </template>-->
    </a-table>


    <a-table
        style="border-radius: 15px;overflow: hidden;border: 1px solid rgba(0,0,0,0.2)"
        ref="table"
        v-if="modeChange == 'update'"
        :columns="columnsUpdate"
        :data-source="dataUpdate"
        :rowKey="(record) => record.pid"
        :pagination="false"

    >
      <!--        eslint-disable-next-line vue/no-unused-vars -->
      <template slot="operation" slot-scope="text, record">
        <a @click="showContent(record)">查看</a>
      </template>
    </a-table>


    <a-table
        style="border-radius: 15px;overflow: hidden;border: 1px solid rgba(0,0,0,0.2)"
        ref="table"
        v-if="modeChange == 'helpful'"
        :columns="helpfulUpdate"
        :data-source="helpfulData"
        :rowKey="(record) => record.pid"
        :pagination="false"


    >

      <!--        eslint-disable-next-line vue/no-unused-vars -->
      <template slot="operation" slot-scope="text, record">
        <a @click="showContent(record)">查看</a>
      </template>
    </a-table>


    <a-modal v-model="contentConfig.isShow" :width="1000" :title="this.contentConfig.contentData.posts_name"
             :footer="null" @cancel="()=>contentConfig.isShow=false">
      <div style="display: flex;justify-content: center;align-items: center">
        <p>创建时间:{{ this.contentConfig.contentData.create_time }}</p>
        <p style="margin-left: 100px">最后更新时间:{{ this.contentConfig.contentData.update_time }}</p>
      </div>
      <p v-html="this.contentConfig.contentData.posts_content"></p>
    </a-modal>
  </div>
</template>

<script>
const key = '123'
export default {
  props: ['isComp'],
  name: "UseRecord",
  data() {
    return {
      searchData: '',
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",
          scopedSlots: {customRender: 'id'},
        },
        {
          title: 'Steam17 位ID',
          dataIndex: 'steamid',
          key: 'steamid',
          align: "center",
          scopedSlots: {customRender: 'steamid'},
        },
        {
          title: '是否付费',
          dataIndex: 'is_pay',
          key: 'is_pay',
          align: "center",
          scopedSlots: {customRender: 'is_pay'},
        },
        {
          title: '作图时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
          scopedSlots: {customRender: 'create_time'},
        },
        // {
        //   title: '下载',
        //   dataIndex: 'operation',
        //   align: "center",
        //   scopedSlots: {customRender: 'operation'},
        // },

      ],
      data: [],
      returnBack: {},

      columnsUpdate: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",
          scopedSlots: {customRender: 'id'},
        },
        {
          title: '更新简述',
          dataIndex: 'posts_name',
          key: 'posts_name',
          align: "center",
          ellipsis: "true",
          scopedSlots: {customRender: 'posts_name'},
        },
        {
          title: '发布时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
          scopedSlots: {customRender: 'create_time'},
        },
        {
          title: '',
          dataIndex: 'operation',
          width: '180px',
          scopedSlots: {customRender: 'operation'},
        },
      ],
      dataUpdate: [],
      updateBack: {},

      helpfulUpdate: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",
          scopedSlots: {customRender: 'id'},
        },
        {
          title: '帮助中心',
          dataIndex: 'posts_name',
          key: 'posts_name',
          align: "center",
          ellipsis: "true",
          scopedSlots: {customRender: 'posts_name'},
        },
        {
          title: '发布时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
          scopedSlots: {customRender: 'create_time'},
        },
        {
          title: '',
          dataIndex: 'operation',
          width: '180px',
          scopedSlots: {customRender: 'operation'},
        },
      ],
      helpfulData: [],
      helpfulBack: {},


      curr: 1,
      upCurr: 1,
      helpCurr: 1,

      modeChange: 'record',

      contentConfig: {
        isShow: false,
        contentData: {},
      }
    }
  },
  methods: {
    showContent(e) {
      this.contentConfig.isShow = true
      this.contentConfig.contentData = e
    },
    async onSSS(e) {
      this.searchData = e
      this.$message.loading({content: '搜索中...', key, duration: 2})
      this.data = []
      this.returnBack = JSON.parse((await (this.$axios.post('/user/api/SearchDrawingLog', {
        page: 1,
        pageCount: 12,
        steamid: e
      }))).data.data)
      this.$message.success({content: '完成', key, duration: 2})

      this.data = this.returnBack.list
    },
    download(e) {
      if (e.download_url == null) {
        this.$message.error({content: 'VIP做图下载后,保存30天左右', key: 123, duration: 1})
        return
      }
      this.$message.success({content: '开始下载...', key: 123, duration: 1})

      let alink = document.createElement('a')
      alink.href = e.download_url
      alink.download = `${e.steamid} ${e.create_time}.png`
      alink.click()
    }

  },
  watch: {
    curr: {
      async handler(n) {
        this.returnBack = JSON.parse((await (this.$axios.post('/user/api/SearchDrawingLog', {
          page: n,
          pageCount: 12,
          steamid: this.searchData
        }))).data.data)
        this.data = this.returnBack.list
      }
    },

    upCurr: {
      async handler(n) {
        this.updateBack = JSON.parse((await (this.$axios.get('/Api/getHelpsList', {
          params: {
            page: n,
            type: 0,
            pageCount: 12
          }
        }))).data.data)
        this.dataUpdate = this.updateBack.list
      }
    },

    helpCurr: {
      async handler(n) {
        this.helpfulBack = JSON.parse((await (this.$axios.get('/Api/getHelpsList', {
          params: {
            page: n,
            type: 1,
            pageCount: 12
          }
        }))).data.data)
        this.helpfulData = this.helpfulBack.list
      }
    }


  },
  async mounted() {
    console.log(this.isComp)
    this.returnBack = JSON.parse((await (this.$axios.post('/user/api/SearchDrawingLog', {
      page: 1,
      pageCount: 12,
      steamid: ''
    }))).data.data)
    this.data = this.returnBack.list
    // console.log(this.data)

    this.updateBack = JSON.parse((await (this.$axios.get('/Api/getHelpsList', {
      params: {
        page: 1,
        type: 0,
        pageCount: 12
      }
    }))).data.data)
    this.dataUpdate = this.updateBack.list


    this.helpfulBack = JSON.parse((await (this.$axios.get('/Api/getHelpsList', {
      params: {
        page: 1,
        type: 1,
        pageCount: 12
      }
    }))).data.data)
    this.helpfulData = this.helpfulBack.list
  }


}
</script>

<style scoped lang="less">
::v-deep {
  img {
    max-width: 100%;
  }
}

</style>